import React from 'react'
import MainLayout from '../../components/MainLayout'
import DevelopersSection from './containers/DevelopersSection'
import ProjectsSection from './containers/ProjectsSection'
import UnitsSection from './containers/UnitsSection'
import DiscoverHero from './containers/DiscoverHero'
import SEO from '../../components/SEO'

const DiscoverPage = () => {
  //
  return (
    <div>
      <SEO
        title={'Discover | Nubaq'}
        description={'Find your dream home with Nubaq comprehensive real estate services. Explore apartments, villas, and more tailored to your needs.'}
        keywords={'real estate, buy, house, home, apartments, villas, property, properties, sell, finish, rent, duplex, twin house, chalet, north coast, sahel, gouna, sokhna, cairo, new capital, zayed'}
        url={'https://www.nubaq.com/discover'}
        image={'https://www.nubaq.com/assets/Icon.png'}
      />
      <MainLayout>
        <DiscoverHero />
        <DevelopersSection />
        <ProjectsSection />
        <UnitsSection />
      </MainLayout>
    </div>
  )
}

export default DiscoverPage
