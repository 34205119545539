import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [isSelectedLanguageEnglish, setIsSelectedLanguageEnglish] = useState(true);
  const [units, setUnits] = useState([]);
  const [developers, setDevelopers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  //to upload
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [unitsResponse, projectsResponse, developersResponse] = await Promise.all([
          axios.get('https://legendary-cucurucho-9bea38.netlify.app/.netlify/functions/api/api/units'),
          axios.get('https://legendary-cucurucho-9bea38.netlify.app/.netlify/functions/api/api/projects'),
          axios.get('https://legendary-cucurucho-9bea38.netlify.app/.netlify/functions/api/api/developers'),
        ]);

        setUnits(unitsResponse.data);
        setProjects(projectsResponse.data);
        setDevelopers(developersResponse.data);
      } catch (error) {
        console.error('Failed to load data: ', error);
      } finally {
        setLoading(false); // Only set loading to false after all requests complete
      }
    };

    fetchData();
  }, []);

  const getDeveloperLogo = (developerName) => {
    const developer = developers.find((dev) => dev.name === developerName);
    return developer?.logoLink;
  };

  const getProjectLogo = (projectName) => {
    const project = projects.find((proj) => proj.name === projectName);
    return project?.logoLink;
  };

  const getDeveloperById = async (id) => {
    const dev = developers.find((developer) => developer._id === id);
    return dev;
  };

  const getProjectById = async (id) => {
    const proj = projects.find((project) => project._id === id);
    return proj;
  };

  const getProjectsFromDeveloper = async (devName) => {
    const projs = projects.filter((project) => project.developer === devName);
    return projs;
  };

  const getUnitsFromDeveloper = async (devName) => {
    const uns = units.filter((unit) => unit.developer === devName);
    return uns;
  };

  const getUnitsFromProject = async (projName) => {
    const uns = units.filter((unit) => unit.project === projName);
    return uns;
  };

  const handleLanguageChange = (e) => {
    setIsSelectedLanguageEnglish(e);
  };

  const locationsOptions = [
    { value: '', label: '' },
    { value: 'Cairo East', label: 'Cairo East' },
    { value: 'Cairo West', label: 'Cairo West' },
    { value: 'Old Cairo', label: 'Old Cairo' },
    { value: 'North Coast', label: 'North Coast' },
    { value: 'Red Sea', label: 'Red Sea' },
    { value: 'UAE_Dubai', label: 'UAE_Dubai' },
  ];
  const arabicLocationsOptions = [
    { value: '', label: '' },
    { value: 'شرق القاهرة', label: 'شرق القاهرة' },
    { value: 'غرب القاهرة', label: 'غرب القاهرة' },
    { value: 'القاهرة القديمة', label: 'القاهرة القديمة' },
    { value: 'الساحل الشمالى', label: 'الساحل الشمالى' },
    { value: 'البحر الاحمر', label: 'البحر الاحمر' },
    { value: 'الأمارات دبى', label: 'الأمارات دبى' },
  ];
  const PropertyTypeOptions = [
    { value: '', },
    { value: 'Studio', },
    { value: 'Apartment', },
    { value: 'Duplex', },
    { value: 'Villa', },
    { value: 'Twin House', },
    { value: 'Town House', },
    { value: 'Stand Alone', },
    { value: 'Sky Villa', },
    { value: 'Sky Loft', },
    { value: 'Lake House', },
    { value: 'Mansion', },
    { value: 'Condo', },
    { value: 'Quadro', },
    { value: 'Food & Beverages', },
    { value: 'Villa Apartment', },
    { value: 'Chalet', },
    { value: 'Loft', },
    { value: 'Cabin', },
    { value: 'Office', },
    { value: 'Clinic', },
    { value: 'Retail', }
  ];
  const deliveryOptions = [
    { value: '', label: '' },
    { value: 'Ready to move', label: 'Ready to move' },
    { value: '6 Months', label: '6 Months' },
    { value: '1 Year', label: '1 Year' },
    { value: '1.5 Year', label: '1.5 Year' },
    { value: '2 Years', label: '2 Years' },
    { value: '2.5 Year', label: '2.5 Year' },
    { value: '3 Years', label: '3 Years' },
    { value: '3.5 Year', label: '3.5 Year' },
    { value: '4 Years', label: '4 Years' },
    { value: '4.5 Year', label: '4.5 Year' },
    { value: '5 Years', label: '5 Years' },
  ]

  const cairoEastZones = [
    'Any',
    'Nasr City',
    'New Cairo',
    'Mostakbal City',
    'New Administrative Capital',
    'New Helioplis',
    'Madinaty',
    'El Rehab',
    'El Obour',
    'El Shrouk'
  ]
  const cairoWestZones = [
    'Any',
    'El Sheikh Zayed',
    'October',
    'Eastern Expansions',
    'Northern Expansions',
    'Green Belt',
    'New Zayed',
    'October Gardens',
    'New October',
    'Wahat Road',
    'New Sphinx'
  ]
  const oldCairoZones = [
    'Any',
    'Helioplis',
    'Downtown',
    'Zamalek',
    'El Mohandseen',
    'El Fustat',
    'Dokki',
    'Maadi',
    'Mokattam'
  ]
  const northCoastZones = [
    '',
    'Alexandria',
    'Old Sahel',
    'Alamein',
    'New Alamein',
    'Sidi Abdelrahman',
    'El Dabaa',
    'Ras El Hikma',
    'Sidi Heneish',
    'Matrouh'
  ]
  const redSeaZones = [
    '',
    'Suez',
    'Ain Sokhna',
    'Galala',
    'Zafarana',
    'El Gouna',
    'Hurghada',
    'Sahl Hashiesh',
    'Makadi Bay',
    'Somabay',
  ]
  const arabicCairoEastZones = [
    'Any',
    'مدينة نصر',
    'القاهرة الجديدة',
    'مستقبل سيتى',
    'العاصمة الادارية الجديدة',
    'هليوبلس الجديدة',
    'مدينتى',
    'الرحاب',
    'مدينة العبور',
    'مدينة الشروق'
  ]
  const arabicCairoWestZones = [
    'Any',
    'الشيخ زايد',
    'اكتوبر',
    'التوسعات الشرقيه',
    'التوسعات الشماليه',
    'الحزام الاخضر',
    'زايد الجديدة',
    'حدائق اكتوبر',
    'اكتوبر الجديدة',
    'طريق الواحات',
    'مدينة سفينكس الجديدة'
  ]
  const arabicOldCairoZones = [
    'Any',
    'مصر الجديدة',
    'وسط البلد',
    'الزمالك',
    'المهندسين',
    'الفسطاط',
    'الدقى',
    'المعادى',
    'المقطم'
  ]
  const arabicNorthCoastZones = [
    '',
    'الأسكندرية',
    'الساحل القديم',
    'العلمين',
    'العلمين الجديدة',
    'سيدى عبد الرحمن',
    'الضبعة',
    'راس الحكمة',
    'سيدى حنيش',
    'مطروح'
  ]
  const arabicRedSeaZones = [
    '',
    'السويس',
    'العين السخنة',
    'الجلالة',
    'الزعفرانة',
    'الجونة',
    'الغردقة',
    'سهل حشيش',
    'مكادى باى',
    'سوماباى',
  ]

  const getZone = (loc) => {
    switch (loc) {
      case 'Cairo East':
        return cairoEastZones;
      case 'Cairo West':
        return cairoWestZones;
      case 'Old Cairo':
        return oldCairoZones;
      case 'Red Sea':
        return redSeaZones;
      case 'North Coast':
        return northCoastZones;
      default:
        break;
    }
  }
  const getArabicZone = (loc) => {
    switch (loc) {
      case 'شرق القاهرة':
        return arabicCairoEastZones;
      case 'غرب القاهرة':
        return arabicCairoWestZones;
      case 'القاهرة القديمة':
        return arabicOldCairoZones;
      case 'البحر الاحمر':
        return arabicRedSeaZones;
      case 'الساحل الشمالى':
        return arabicNorthCoastZones;
      default:
        break;
    }
  }

  return (
    <AppContext.Provider
      value={{
        units,
        developers,
        projects,
        getDeveloperLogo,
        getProjectLogo,
        isSelectedLanguageEnglish,
        handleLanguageChange,
        getDeveloperById,
        getProjectById,
        getProjectsFromDeveloper,
        getUnitsFromDeveloper,
        getUnitsFromProject,
        loading,
        locationsOptions,
        arabicLocationsOptions,
        deliveryOptions,
        PropertyTypeOptions,
        getZone,
        getArabicZone,
        isMenuOpened,
        setIsMenuOpened
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
