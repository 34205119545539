import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { Menu } from '@headlessui/react';
import { FiDollarSign } from 'react-icons/fi';
import { BiBed, BiSearch } from 'react-icons/bi';
import { BsHouse } from 'react-icons/bs';
import { GoLocation } from 'react-icons/go';
import { AppContext } from '../AppContext';

const SearchComponent = () => {

    const [isLocationsOpened, setIsLocationsOpened] = useState('')
    const [isBedroomOpened, setIsBedroomOpened] = useState('')
    const [isPropertyTypeOpened, setIsPropertyTypeOpened] = useState('')
    const [isCashOpened, setIsCashOpened] = useState('');
    const [budget, setBudget] = useState('')
    const [location, setLocation] = useState('')
    const [bedrooms, setBedrooms] = useState('')
    const [propertyType, setPropertyType] = useState('')
    const [isCash, setIsCash] = useState({});
    const [downPayment, setDownPayment] = useState('');
    const [quarterInstallment, setQuarterInstallment] = useState('');
    const [installmentsYears, setInstallmentsYears] = useState('');

    const navigate = useNavigate();
    const { isSelectedLanguageEnglish } = useContext(AppContext);

    const isCashOptions = [
        { value: '', label: 'Any' },
        { value: 'true', label: 'Cash' },
        { value: 'false', label: 'Installments' },
    ]
    const arabicIsCashOptions = [
        { value: '', label: 'الكل' },
        { value: 'true', label: 'كاش' },
        { value: 'false', label: 'تقسيط' },
    ]

    const EnglishLocationsOptions = [
        { value: 'Any', label: 'Any' },
        { value: 'Cairo East', label: 'Cairo East' },
        { value: 'Cairo West', label: 'Cairo West' },
        { value: 'Old Cairo', label: 'Old Cairo' },
        { value: 'North Coast', label: 'North Coast' },
        { value: 'Red Sea', label: 'Red Sea' },
        { value: 'UAE_Dubai', label: 'UAE_Dubai' },
    ];
    const ArabicLocationsOptions = [
        { value: '', label: '' },
        { value: 'شرق القاهرة', label: 'شرق القاهرة' },
        { value: 'غرب القاهرة', label: 'غرب القاهرة' },
        { value: 'القاهرة القديمة', label: 'القاهرة القديمة' },
        { value: 'الساحل الشمالى', label: 'الساحل الشمالى' },
        { value: 'البحر الاحمر', label: 'البحر الاحمر' },
        { value: 'الأمارات دبى', label: 'الأمارات دبى' },
    ];
    const locationOptions = isSelectedLanguageEnglish ? EnglishLocationsOptions : ArabicLocationsOptions;
    const bedroomOptions = [
        { value: 'Any', },
        { value: '1', },
        { value: '2', },
        { value: '3', },
        { value: '4', },
        { value: '5+', },
    ];
    const EnglishPropertyTypeOptions = [
        { value: 'Any', label: 'Any' },
        { value: 'Studio', label: 'Studio' },
        { value: 'Apartment', label: 'Apartment' },
        { value: 'Apartment with garden', label: 'Apartment with garden' },
        { value: 'Duplex', label: 'Duplex' },
        { value: 'Villa', label: 'Villa' },
        { value: 'Twin House', label: 'Twin House' },
        { value: 'Town House', label: 'Town House' },
        { value: 'Pent House', label: 'Pent House' },
        { value: 'Stand Alone', label: 'Stand Alone' },
        { value: 'Sky Villa', label: 'Sky Villa' },
        { value: 'Sky Loft', label: 'Sky Loft' },
        { value: 'Loft', label: 'Loft' },
        { value: 'Cabin', label: 'Cabin' },
        { value: 'Condo', label: 'Condo' },
        { value: 'Quadro', label: 'Quadro' },
        { value: 'Food & Beverages', label: 'Food & Beverages' },
        { value: 'Villa Apartment', label: 'Villa Apartment' },
        { value: 'Chalet', label: 'Chalet' },
        { value: 'Office', label: 'Office' },
        { value: 'Clinic', label: 'Clinic' },
        { value: 'Retail', label: 'Retail' },
      ];
      const ArabicPropertyTypeOptions = [
        { value: 'Any', label: 'الكل' },
        { value: 'Studio', label: 'استوديو' },
        { value: 'Apartment', label: 'شقة' },
        { value: 'Apartment with garden', label: 'شقة بحديقة' },
        { value: 'Duplex', label: 'دوبلكس' },
        { value: 'Villa', label: 'فيلا' },
        { value: 'Twin House', label: 'توين هاوس' },
        { value: 'Town House', label: 'تاون هاوس' },
        { value: 'Pent House', label: 'بينت هاوس' },
        { value: 'Stand Alone', label: 'فيلا منفصلة' },
        { value: 'Sky Villa', label: 'سكاى فيلا' },
        { value: 'Sky Loft', label: 'سكاى لوفت' },
        { value: 'Loft', label: 'لوفت' },
        { value: 'Cabin', label: 'كابينه' },
        { value: 'Condo', label: 'فيلا كوندو' },
        { value: 'Quadro', label: 'فيلا كوادرو' },
        { value: 'Food & Beverages', label: 'مطعم' },
        { value: 'Villa Apartment', label: 'شقة فيلا' },
        { value: 'Chalet', label: 'شالية' },
        { value: 'Office', label: 'مكتب' },
        { value: 'Clinic', label: 'عيادة' },
        { value: 'Retail', label: 'تجارى' },
      ];
    const propertyTypeOptions = isSelectedLanguageEnglish ? EnglishPropertyTypeOptions : ArabicPropertyTypeOptions;

    const handleBudgetChange = (e) => {
        setBudget(e.target.value);
    }

    const handleSearch = () => {

        navigate('/units', {
            state: {
                location: location,
                bedrooms: bedrooms,
                propertyType: propertyType,
                budget: budget,
                downPayment: downPayment,
                quarterInstallment: quarterInstallment,
                installmentsYears: installmentsYears,
                isCash: isCash.value,
            }
        });
    };

    const paymentPlanOptions = isSelectedLanguageEnglish ? isCashOptions : arabicIsCashOptions;

    return (
        <div className='hidden relative md:flex items-center justify-center rounded-full w-3/4 bg-white min-h-[65px]'>
            <Menu as='div' className='dropdown relative h-full rounded-l-full '>
                <Menu.Button onClick={() => setIsPropertyTypeOpened(!isPropertyTypeOpened)}
                    className={`dropdown-btn w-full h-full text-left rounded-l-full bg-white ${isPropertyTypeOpened ? 'border-green-300' : ''}`}>
                    <BsHouse className='dropdown-icon-primary text-2xl' />
                    <div>
                        <div className='text-lg '>
                            {propertyType}
                        </div>
                        <div className='text-lg'>
                            {propertyType ? '' : isSelectedLanguageEnglish ? 'Property type' : 'نوع الوحدة'}
                        </div>
                    </div>
                    {isPropertyTypeOpened ? (
                        <RiArrowUpSLine className='dropdown-icon-secondary' />
                    ) : (
                        <RiArrowDownSLine className='dropdown-icon-secondary' />
                    )}
                </Menu.Button>

                <Menu.Items className='dropdown-menu rounded'>
                    {propertyTypeOptions.map((type, index) => {
                        return (
                            <Menu.Item
                                onClick={(e) => { setPropertyType(type.value) }}
                                className='cursor-pointer hover:text-green-400 transition-all '
                                as='li'
                                key={index}>
                                {type.label}
                            </Menu.Item>
                        );
                    })}
                </Menu.Items>
            </Menu>
            <Menu as='div' className='dropdown h-full rounded-none relative'>
                <Menu.Button onClick={() => setIsBedroomOpened(!isBedroomOpened)}
                    className={`dropdown-btn h-full w-full rounded-none text-left bg-white ${isBedroomOpened ? 'border-green-300' : ''}`}>
                    <BiBed className='dropdown-icon-primary text-2xl' />
                    <div>
                        <div className='text-lg '>
                            {bedrooms}
                        </div>
                        <div className='text-lg'>
                            {bedrooms ? '' : isSelectedLanguageEnglish ? 'Bedrooms' : 'الغرف'}
                        </div>
                    </div>
                    {isBedroomOpened ? (
                        <RiArrowUpSLine className='dropdown-icon-secondary' />
                    ) : (
                        <RiArrowDownSLine className='dropdown-icon-secondary' />
                    )}


                </Menu.Button>

                <Menu.Items className='dropdown-menu rounded'>
                    {bedroomOptions.map((bedrooms, index) => {
                        return (
                            <Menu.Item
                                onClick={(e) => { setBedrooms(bedrooms.value) }}
                                className='cursor-pointer hover:text-green-400 transition-all '
                                as='li'
                                key={index}>
                                {bedrooms.value}
                            </Menu.Item>
                        );
                    })}
                </Menu.Items>
            </Menu>
            <Menu as='div' className='dropdown h-full rounded-none relative'>
                <Menu.Button onClick={() => setIsLocationsOpened(!isLocationsOpened)}
                    className={`dropdown-btn w-full h-full rounded-none text-left bg-white ${isLocationsOpened ? 'border-green-300' : ''}`}>
                    <GoLocation className='dropdown-icon-primary text-2xl' />
                    <div>
                        <div className='text-lg '>
                            {location}
                        </div>
                        <div className='text-lg'>
                            {location ? '' : isSelectedLanguageEnglish ? 'Location' : 'الموقع'}
                        </div>
                    </div>
                    {isLocationsOpened ? (
                        <RiArrowUpSLine className='dropdown-icon-secondary' />
                    ) : (
                        <RiArrowDownSLine className='dropdown-icon-secondary' />
                    )}


                </Menu.Button>
                <Menu.Items className='dropdown-menu rounded'>
                    {locationOptions.map((location, index) => {
                        return (
                            <Menu.Item
                                onClick={(e) => { setLocation(location.value) }}
                                className='cursor-pointer hover:text-green-400 transition-all '
                                as='li'
                                key={index}>
                                {location.label}
                            </Menu.Item>
                        );
                    })}
                </Menu.Items>
            </Menu>
            <Menu as='div' className='dropdown h-full rounded-none relative'>
                <Menu.Button onClick={() => setIsCashOpened(!isCashOpened)}
                    className={`dropdown-btn w-full h-full rounded-none text-left bg-white ${isCashOpened ? 'border-green-300' : ''}`}>
                    <FiDollarSign className='dropdown-icon-primary text-2xl' />
                    <div>
                        <div className='text-lg '>
                            {isCash.label}
                        </div>
                        <div className='text-lg'>
                            {isCash.label ? '' : isSelectedLanguageEnglish ? 'Payment Plan' : 'نظام السداد'}
                        </div>
                    </div>
                    {isCashOpened ? (
                        <RiArrowUpSLine className='dropdown-icon-secondary' />
                    ) : (
                        <RiArrowDownSLine className='dropdown-icon-secondary' />
                    )}


                </Menu.Button>
                <Menu.Items className='dropdown-menu rounded'>
                    {paymentPlanOptions.map((option, index) => {
                        return (
                            <Menu.Item
                                onClick={(e) => { setIsCash(option) }}
                                className='cursor-pointer hover:text-green-400 transition-all '
                                as='li'
                                key={index}>
                                {option.label}
                            </Menu.Item>
                        );
                    })}
                </Menu.Items>
            </Menu>

            {(isCash.value === 'true') &&
                (<div className='absolute -bottom-full bg-white right-12 group h-full border flex items-center border-gray-200'>
                    <FiDollarSign className='dropdown-icon-primary text-2xl' />
                    <input
                        type='text'
                        value={budget}
                        placeholder={isSelectedLanguageEnglish ? 'Budget...' : '...الميزانيه'}
                        className='px-4 py-2 h-full focus:outline-none'
                        onChange={handleBudgetChange}
                    />
                </div>)
            }
            {
                (isCash.value === 'false') &&
                (
                    <div className='flex flex-wrap absolute -bottom-full bg-white h-full right-12'>
                        <div className='h-full group border flex items-center bg-white border-gray-200'>
                            <FiDollarSign className='dropdown-icon-primary text-2xl' />
                            <input
                                type='text'
                                value={downPayment}
                                placeholder={isSelectedLanguageEnglish ? 'Downpayment...' : '...المقدم'}
                                className='px-4 py-2 h-full focus:outline-none group-focus:border group-focus:border-nubaqgreen'
                                onChange={(e) => setDownPayment(e.target.value)}
                            />
                        </div>
                        <div className='h-full group border flex items-center bg-white border-gray-200'>
                            <FiDollarSign className='dropdown-icon-primary text-2xl' />
                            <input
                                type='text'
                                value={quarterInstallment}
                                placeholder={isSelectedLanguageEnglish ? 'Quarter Installment...' : '...القسط الربع سنوى'}
                                className='px-4 py-2 h-full focus:outline-none group-focus:border group-focus:border-nubaqgreen'
                                onChange={(e) => setQuarterInstallment(e.target.value)}
                            />
                        </div>
                        <div className='h-full group border flex items-center bg-white border-gray-200'>
                            <FiDollarSign className='dropdown-icon-primary text-2xl' />
                            <input
                                type='text'
                                value={installmentsYears}
                                placeholder={isSelectedLanguageEnglish ? 'Installments Years...' : '...سنوات الأقساط'}
                                className='px-4 py-2 h-full focus:outline-none group-focus:border group-focus:border-nubaqgreen'
                                onChange={(e) => setInstallmentsYears(e.target.value)}
                            />
                        </div>
                    </div>
                )
            }
            {console.log(isCash.value)}
            <button onClick={() => { handleSearch() }} className='px-4 text-[28px] text-white hover:animate-pulse transition-all duration-500 bg-green-300 h-full rounded-r-full'><BiSearch /></button>
        </div>
    )
}

export default SearchComponent
