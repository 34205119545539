import React, { useEffect, useState, useRef, useContext } from 'react';
import images from '../../../constants/Images';
import TimeToCallDropdown from '../../../components/TimeToCallDropdown';
import axios from 'axios';
import { AppContext } from '../../../AppContext';

const ContactUs = () => {
  const { isSelectedLanguageEnglish } = useContext(AppContext);

  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const getCurrentTimestamp = () => {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year}, ${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    clientName: '',
    clientEmail: '',
    clientNumber: '',
    timeToCall: '',
    message: '',
    status: 'Open',
    requestType: 'Random Contact',
    timestamp: getCurrentTimestamp(),
  });

  const [error, setError] = useState('');

  const sectionRef = useRef(null);

  useEffect(() => {
    const currentSection = sectionRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(currentSection);
        }
      },
      { threshold: 0.2 }
    );

    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) observer.unobserve(currentSection);
    };
  }, []);

  const clearForm = () => {
    setFormData({
      clientName: '',
      clientEmail: '',
      clientNumber: '',
      timeToCall: '',
      message: '',
      status: 'Open',
      requestType: 'Random Contact',
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTimeToCallChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      timeToCall: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(''); // Reset error message
    try {
      await axios.post(
        'https://legendary-cucurucho-9bea38.netlify.app/.netlify/functions/api/api/contact-requests',
        { params: { formData } }
      );
      alert('Your request has been submitted successfully. We will contact you soon.');
      clearForm();
    } catch (err) {
      setError('Error submitting your request, please try again...');
    }
    setLoading(false);
  };

  let circleCommonClasses = 'h-2.5 w-2.5 bg-green-400 rounded-full';

  return (
    <section
      id="contactus"
      ref={sectionRef}
      className={`relative flex ${!isSelectedLanguageEnglish && 'flex-row-reverse'} gap-4 w-full p-16 transition-opacity duration-1000 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}
    >
      {loading && (
        <div className="absolute z-10 bg-opacity-50 bg-black w-full h-full flex items-center justify-center">
          <div className="flex items-center justify-center rounded-full h-20 w-20 bg-white">
            <div className="flex items-center justify-center w-full h-screen-minus-245">
              <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
              <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
              <div className={`${circleCommonClasses} animate-bounce400`}></div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col w-full lg:flex-row gap-4">
        {/* Form Section */}
        <div className="w-full lg:w-1/2">
          <div className="text-4xl font-bold text-nubaqgreen w-full text-center pb-6">
            {isSelectedLanguageEnglish ? 'Leave a message' : 'اترك لنا رسالة'}
          </div>

          <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-2 text-sm">
            <div className="flex flex-col gap-1">
              <span className="px-1">{isSelectedLanguageEnglish ? 'Name' : 'الاسم'}</span>
              <input
                name="clientName"
                value={formData.clientName}
                onChange={handleChange}
                className="focus:outline-none p-2 h-full rounded-md focus:border-green-300 border-2"
                type="text"
                placeholder={isSelectedLanguageEnglish ? 'Enter your name...' : '...الاسم'}
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="px-1">{isSelectedLanguageEnglish ? 'Mobile Number' : 'رقم الهاتف'}</span>
              <input
                name="clientNumber"
                value={formData.clientNumber}
                onChange={handleChange}
                className="focus:outline-none p-2 h-full rounded-md focus:border-green-300 border-2"
                type="text"
                placeholder={isSelectedLanguageEnglish ? 'Enter your number...' : '...رقم الهاتف'}
                required
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="px-1">{isSelectedLanguageEnglish ? 'Email' : 'البريد الاليكترونى'}{' '}
                <span className="text-gray-600">
                  {isSelectedLanguageEnglish ? '( Optional )' : '( اختيارى )'}
                </span>
              </span>
              <input
                name="clientEmail"
                value={formData.clientEmail}
                onChange={handleChange}
                className="focus:outline-none p-2 h-full rounded-md focus:border-green-300 border-2"
                type="email"
                placeholder={isSelectedLanguageEnglish ? 'Enter your email...' : '...البريد الاليكترونى'}
              />
            </div>
            <div className="flex flex-col gap-1">
              <span className="px-1">{isSelectedLanguageEnglish ? 'Time to call' : 'ميعاد الاتصال'}</span>
              <TimeToCallDropdown handleInputChange={handleTimeToCallChange} />
            </div>
            <div className="flex flex-col col-span-2 gap-1 text-sm">
              <span className="px-1">{isSelectedLanguageEnglish ? 'Message' : 'الرسالة'}{' '}
                <span className="text-gray-600">
                  {isSelectedLanguageEnglish ? '( Optional )' : '( اختيارى )'}
                </span>
              </span>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="focus:outline-none h-full p-2 rounded-md focus:border-green-300 border-2"
                placeholder={isSelectedLanguageEnglish ? 'Type a message...' : '...اكتب لنا رساله'}
              />
            </div>
            {error && <div className="text-red-500 text-center py-2">{error}</div>}
            <div className="p-8 flex items-center justify-center w-full text-xl col-span-2">
              <button className="px-12 py-4 rounded-xl border-2 border-green-300 hover:bg-green-600 hover:border-green-600 hover:text-white font-semibold transition-all duration-500">
                {isSelectedLanguageEnglish ? 'Submit' : 'ارسال'}
              </button>
            </div>
          </form>
        </div>

        {/* Image Section */}
        <div className="w-full lg:w-1/2 mt-8 lg:mt-0 hidden lg:block">
          <img
            src={images.ContactImg}
            alt="Contact"
            className="max-w-screen-sm w-full h-full rounded-xl shadow-lg"
          />
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
